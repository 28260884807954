
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

body {
  font-family: "Montserrat", sans-serif;
  touch-action: manipulation; 
}
input:active, input:focus ,select:focus{
  outline: none;
  border: none;
  box-shadow: none;
}
.custom-shadow {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield; 
  appearance: textfield; 
}
input:focus, select:focus {
  font-size: 16px; 
}