@keyframes slideDown {
    from {
      transform: translateY(-100%);
    }
    to {
      transform: translateY(0);
    }
  }
  
  .animate-slideDown {
    animation: slideDown 0.5s ease-out;
  }
  